import { contacts } from "../constants";
import styles from "../style";
import ContactCard from "./ContactCard";

const ContactUs = () => (
    <section
        id="contactUs"
        className={`${styles.paddingY} ${styles.flexCenter} flex-col relative `}
    >
        <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient bottom-40" />

        <div className="w-full flex justify-between items-center md:flex-row flex-col sm:mb-16 mb-6 relative z-[1]">
            <h2 className={styles.heading2}>Contact Us</h2>
            <div className="w-full md:mt-0 mt-6">
                <div className="flex flex-wrap sm:justify-start justify-center w-full feedback-container relative z-[1]">
                    {contacts.map((card) => (
                        <ContactCard key={card.id} {...card} />
                    ))}
                </div>
            </div>
        </div>
    </section>
);

export default ContactUs;
