import hilingTrail from "../assets/hiking-trail.jpeg";
import styles, { layout } from "../style";

const Schedule = () => (
    <section id="schedule" className={layout.sectionReverse}>
        <div className={layout.sectionImgReverse}>
            <img
                src={hilingTrail}
                alt="schedule"
                className="w-[100%] h-[100%] relative z-[5]"
            />

            {/* gradient start */}
            <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient" />
            <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient" />
            {/* gradient end */}
        </div>

        <div className={layout.sectionInfo}>
            <h2 className={`${styles.heading2} text-center`}>Schedule</h2>
            <h3 className={`${styles.heading3} text-center`}>
                <span className="text-gradient">Thursday July 25, 2024</span>{" "}
                <br />
                <span className="text-gradient">Friday July 26, 2024</span>{" "}
                <br />
                <span className="text-gradient">Saturday July 27, 2024</span>{" "}
                <br />
                <span className="text-gradient">Sunday July 28, 2024</span>
            </h3>
            <p className={`${styles.paragraph} mt-5`}>
                Let's get together in July 2024 and get to know our family
                more...
            </p>

            <p className={`${styles.paragraph} mt-5`}>
                See more activities and events at YMCA - Snow Mountain Ranch
                website.
            </p>

            <p className={`${styles.paragraph} mt-5`}>
                Please note that you will need to register for the activities
                separately.
            </p>

            <div
                className={`flex flex-row flex-wrap sm:mt-10 mt-6 w-full ${styles.flexCenter}`}
            >
                <a
                    href="https://ymcarockies.org/Locations/Snow-Mountain-Ranch"
                    className={`py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none ${styles}`}
                    target="_blank"
                >
                    Visit YMCA - Snow Mountain Ranch website
                </a>
            </div>
        </div>
    </section>
);

export default Schedule;
