export const navLinks = [
    {
        id: "home",
        title: "Home",
    },
    {
        id: "schedule",
        title: "Schedule",
    },
    {
        id: "travel",
        title: "Travel",
    },
    {
        id: "contactUs",
        title: "Contact Us",
    },
];

export const footerLinks = [
    {
        title: "Useful Links",
        links: [
            {
                name: "About Us",
                link: "https://malayilkudumbam.com/about%20us.html",
            },
            {
                name: "Contact Us",
                link: "https://na.malayilfamily.com/#contactUs",
            },
        ],
    },
    {
        title: "Our Family",
        links: [
            {
                name: "Malayil Kudumbam",
                link: "https://malayilkudumbam.com/index.html",
            },
            {
                name: "Gallery",
                link: "https://malayilkudumbam.com/gallery.html",
            },
        ],
    },
];

export const contacts = [
    {
      id: "1",
      name: "Jacob (Ani)",
      phone: "+1 (925) 336-0185",
    },
    {
      id: "2",
      name: "Merlin",
      phone: "+1 (720) 308-0875",
    },
  ];
