import React from "react";
import styles from "../style";
import mountain from "../assets/mountains-with-water.jpg";

const Hero = () => (
    <section
        id="home"
        className={`flex md:flex-row flex-col sm:pt-8 sm:pb-16 pt-3 pb-6`}
    >
        <div
            className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
        >
            <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
                <p className={`${styles.paragraph} ml-2`}>
                    Let's get together at
                    <span className="text-white"> Denver, Colorado</span> for
                    our family reunion
                </p>
            </div>

            <div className="flex flex-row justify-between items-center w-full">
                <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
                    YMCA of the Rockies - <br className="sm:block hidden" />{" "}
                    <span className="text-gradient">SNOW MOUNTAIN RANCH</span>{" "}
                </h1>
                <div className="ss:flex hidden md:mr-4 mr-0">Test</div>
            </div>

            <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                To all our family members who are in North America - United States and Canada, let's all come together and have fun!
            </p>
        </div>
        <div
            className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}
        >
            <img
                src={mountain}
                alt="billing"
                className="w-[100%] h-[100%] relative z-[5]"
            />
        </div>
    </section>
);

export default Hero;
