import mistyRoad from "../assets/misty-road.jpg";
import styles, { layout } from "../style";

const Travel = () => (
    <section id="travel" className={layout.sectionReverse}>
        <div className={layout.sectionInfo}>
            <h2 className={`${styles.heading2} text-center`}>Travel</h2>
            <p className={`${styles.paragraph} mt-5`}>
                We recommend flying in to Denver International Airport (DEN) and
                booking a rental car from the airport.
            </p>

            <p className={`${styles.paragraph} mt-5`}>
                There is a 2 hour drive from the Denver International Airport
                (DEN) to YMCA of the Rockies - Snow Mountain Ranch
            </p>

            <div
                className={`flex flex-row flex-wrap sm:mt-10 mt-6 w-full ${styles.flexCenter}`}
            >
                <a
                    href="https://www.google.com/maps/dir/Denver+International+Airport,+Denver,+CO,+USA/ymca+snow+mountain+ranch/@39.7766185,-105.6306416,10z/am=t/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x876c67d7dd4dfb35:0xdfe4e20f0e4e67e!2m2!1d-104.6736977!2d39.8495767!1m5!1m1!1s0x876a304411d2118f:0x694ad620ff142b17!2m2!1d-105.9260205!2d39.9926516?entry=ttu"
                    className={`py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none ${styles}`}
                    target="_blank"
                >
                    See in maps
                </a>
            </div>
        </div>

        <div className={layout.sectionImgReverse}>
            <img
                src={mistyRoad}
                alt="travel"
                className="w-[100%] h-[100%] relative z-[5]"
            />
        </div>
    </section>
);

export default Travel;
